body {
  margin:0% auto;
  font-family: 'Helvetica Neue', sans-serif;
}
.appWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.App {
  width: 500px;
}
.header {
  text-transform: uppercase;
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 5px;
}
input {
  display: block;
  border: 1px solid black;
  padding: 5px;
  font-size: 24px;
  width: inherit;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
.singleCase {
  margin: 30px 0 0;
  font-size: 20px;
}
.caseResult {
  border-bottom: 1px solid grey;
  padding-bottom: 5px;
  min-height: 28px;
  height: auto;
}
.caseOptions {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  color: black;
  margin-top: 5px;
}
.caseName {
  flex: 1;
}
.caseCopy {
  flex: 1;
  text-align: right;
  color: #3670DD;
  cursor: pointer;
}
.caseCopy:hover { 
  color: goldenrod;
}
.caseCopied {
  flex: 1;
  text-align: right;
  color: LimeGreen;
}
.footer {
  bottom: 0px;
  position: absolute;
  text-align: center;
  text-align: center;
  width: inherit;
}
.footer a {
  color: black;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: white;
  }
  .App {
    width: 92%;
    height: 350px;
  }
  .header {
    text-transform: uppercase;
    font-size: 48px;
  }
  input {
    width: 100%;
  }
}